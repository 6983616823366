// PreJoinPage.js
import React, {useState} from 'react';
import {PreJoin} from '@livekit/components-react';
import './PreJoinPage.css';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getcoachMeetingToken,getMeetingTimeByEncryptAppointmentId} from "../../api/meeting";
import { Alert } from "antd";

const CoachPreJoinPage = () => {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams()
    const encryptAppointmentId = searchParams.get('appointmengId');
    let [startTime,setStrartTime] = useState(null);

    getMeetingTimeByEncryptAppointmentId({ encryptAppointmentId: encryptAppointmentId }).then(
        (response) => {
            const formattedDateTime = response.data.start_time.slice(0, 16);
            setStrartTime(startTime = formattedDateTime);
        }
    );
    let token = '';
    let serverUrl = '';
    //获取token/serverurl
    getcoachMeetingToken({encryptAppointmentId:encryptAppointmentId}).then(
        (response) => {
            token = response.data.token
            serverUrl = response.data.serverUrl
        }
    );
    const handleClick = () => {
        // if(new Date() < new Date(startTime)){
        //     <Alert
        //         message="会议提醒"
        //         description="会议还未开始"
        //         type="info"
        //         showIcon
        //     />
        //     alert("会议还未开始")
        // }else {
            navigate('/coacheeroom?token=' + token +
                '&serverUrl='+ serverUrl);
        // }

        // Handle the submit event here
    };


    return (
        <div className="prejoin-container">
            <div className="prejoin-content">
                <div className="prejoin-icon">
                    <i className="fas fa-video"></i> {/* Replace with your desired icon */}
                </div>
                <h1>BETTERUS</h1>
                <span>会议开始时间 ： {startTime}</span>
                <br/>
                <span>Please wait while we prepare the conference room.</span>
                <PreJoin onSubmit={handleClick} onValidate={() => true}/>
            </div>
        </div>
    );
};

export default CoachPreJoinPage;
