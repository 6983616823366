import {createBrowserRouter,RouterProvider} from "react-router-dom"

import CoacheePage from "../components/CoacheePage";
import CoachPage from "../components/CoachPage";
import CoacheePreJoinPage from "../components/PreJoin/CoacheePreJoinPage";
import CoachPreJoinPage from "../components/PreJoin/CoachPreJoinPage";


    const router = createBrowserRouter([
        {
            path:"/coachee",
            element:<CoacheePreJoinPage/>
        },
        {    path:'/coach',
            element:<CoachPreJoinPage/>
        },
        {
            path:"/coachroom",
            element:<CoachPage/>
        },
        {
            path:"/coacheeroom",
            element:<CoacheePage/>
        }
    ])


export default router