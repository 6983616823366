import request from "../utils/request";

export function getcoacheeMeetingToken(params) {
    return request({
        url: '/appappointment/getCoacheeTokenByEncryptAppointmentId',
        method: 'get',
        params
    })
}

export function getcoachMeetingToken(params) {
    return request({
        url: '/appappointment/getCoachTokenByEncryptAppointmentId',
        method: 'get',
        params
    })
}
export function getMeetingTimeByEncryptAppointmentId(params) {
    return request({
        url: '/appappointment/getMeetingTimeByEncryptAppointmentId',
        method: 'get',
        params
    })
}
